import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "./Context/AuthContext";
import MessageToUsersContextProvider from "./Context/MessageToUsersContext";
import LLMContextProvider from "./Context/LLMContext";
import LicensingContextProvider from "./Context/LicensingContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthContextProvider>
    <LLMContextProvider>
      <MessageToUsersContextProvider>
        <LicensingContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </LicensingContextProvider>
      </MessageToUsersContextProvider>
    </LLMContextProvider>
  </AuthContextProvider>
);
