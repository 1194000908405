import axios from "axios";
import LocalStorage from "../Helpers/LocalStorage";

const URL = process.env.REACT_APP_API_URL;

var refreshTokenConfig = (refreshtoken) => {
    var config = {
        method: "POST",
        url: `${URL}/api/v1/identity/admins/refresh`,
        headers: {
            // Authorization: `Bearer ${token}`,
            // "Content-Type": "application/json",
        },

        data: {
            refresh_token: refreshtoken,
            client_secret: process.env.REACT_APP_CLIENT_SECRET,
        },

    };
    return config;
};


export const refreshAdminToken = (refreshtoken, onSuccess, onError) => {
    axios(refreshTokenConfig(refreshtoken))
        .then(function (response) {
            console.log(response)
            LocalStorage.setAuthenthicationToken(response.data.access_token);
            LocalStorage.setRefreshToken(response.data.refresh_token);
            onSuccess(response);
        })
        .catch(function (error) {
            onError(error);

        });
};
