import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import { notifyError, notifySuccess } from "../../Helpers/Notifications";

import { useAuthService } from "../../Context/AuthContext";
import PlatformAnalytics from "./subcomponents/PlatformAnalytics";
import PlatformOfferings from "./subcomponents/PlatformOfferings";

const PlatformOptionPage = ({ setIsLoading }) => {
  const OFFERS = "OFFERS";
  const ANALYTICS = "ANALYTICS";

  const [selectedOption, setSelectedOption] = useState(ANALYTICS);

  var content;

  switch (selectedOption) {
    case OFFERS:
      content = <PlatformOfferings setIsLoading={setIsLoading} />;
      break;

    case ANALYTICS:
      content = <PlatformAnalytics setIsLoading={setIsLoading} />;
      break;

    default:
      break;
  }

  useEffect(() => {}, []);

  return (
    <PlatformOptionPageContainer>
      <OptionSelector>
        <OptionButton
          onClick={(e) => setSelectedOption(e.currentTarget.value)}
          value={OFFERS}
          $selected={selectedOption === OFFERS}
        >
          Offers
        </OptionButton>
        <OptionButton
          onClick={(e) => setSelectedOption(e.currentTarget.value)}
          value={ANALYTICS}
          $selected={selectedOption === ANALYTICS}
        >
          Analytics
        </OptionButton>
      </OptionSelector>
      <SelectedOptionWrapper>{content}</SelectedOptionWrapper>
    </PlatformOptionPageContainer>
  );
};

export default PlatformOptionPage;

const PlatformOptionPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 35px 50px;
  background: ${colors.dashBoard.bg};
  height: 100vh;
  position: relative;
`;

const OptionSelector = styled.div`
  display: flex;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  background: ${colors.dashBoard.headerBg};
  width: fit-content;
`;

const OptionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.94px;
  text-align: center;
  color: ${colors.white};
  padding: 12px;
  min-width: 170px;
  border: none;
  background: ${(props) => (props.$selected ? colors.purple : "transparent")};
  opacity: ${(props) => (props.$selected ? 1 : 0.4)};
  cursor: pointer;
  border-radius: 8px;
`;
const SelectedOptionWrapper = styled.div`
  height: 100%;
`;
