import React, { useState, useEffect } from "react";
import Modal from "./Modal";
import styled from "styled-components";
import { colors } from "../../../Assets/styles/colors";
import CustomDatePicker from "../../DashboardComponents/CustomDatePicker";
import { useLicensingContext } from "../../../Context/LicensingContext";
import moment from "moment/moment";
import { notifyError } from "../../../Helpers/Notifications";

const ProjectDetailsModal = ({ isOpen, onClose, projectID, setIsLoading }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [usages, setUsages] = useState([]);

  const { usageForProject } = useLicensingContext();

  const getNameFromItem = (item) => {
    console.log(item);

    if (item.api_key) {
      return "[API] " + item.api_key.name;
    }

    if (item.user) {
      return item.user.email;
    }

    if (item.anonymous_user) {
      return item.anonymous_user.email;
    }

    return "unknown";
  };

  const handleGetUsage = () => {
    let start_date = moment(startDate).startOf("day");
    let end_date = moment(endDate).endOf("day");

    setIsLoading(true);

    usageForProject(
      projectID,
      start_date,
      end_date,
      (response) => {
        const usgs = response.data?.usage;
        setUsages(usgs);
        setIsLoading(false);
      },
      (error) => {
        notifyError("Something went wrong");
        setIsLoading(false);
      }
    );
  };

  // Set initial date range to last month (from start of the month till today)
  useEffect(() => {
    const today = new Date();
    const firstDayOfLastMonth = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      1
    );
    setStartDate(firstDayOfLastMonth);
    setEndDate(today);

    handleGetUsage();
  }, []);

  useEffect(() => {
    handleGetUsage();
  }, [startDate, endDate]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Container>
        <ModalHeader>
          <Title>Analytics</Title>
          <DateGroup>
            <Group>
              <label>Start Date</label>
              <CustomDatePicker value={startDate} setValue={setStartDate} />
            </Group>
            <Group>
              <label>End Date</label>
              <CustomDatePicker value={endDate} setValue={setEndDate} />
            </Group>
          </DateGroup>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>
        <Table>
          <thead>
            <tr>
              <TableHeader>Item</TableHeader>
              <TableHeader>Input Tokens</TableHeader>
              <TableHeader>Audio</TableHeader>
              <TableHeader>Video</TableHeader>
            </tr>
          </thead>
          <tbody>
            {usages.map((usage, index) => (
              <tr key={index}>
                <TableCell>{getNameFromItem(usage)}</TableCell>
                <TableCell>{usage.input_tokens}</TableCell>
                <TableCell>{usage.audio.toFixed(2)}</TableCell>
                <TableCell>{usage.video.toFixed(2)}</TableCell>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>
    </Modal>
  );
};

export default ProjectDetailsModal;

const Container = styled.div`
  width: 100%;
  height: 600px;
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Title = styled.h3`
  flex-grow: 1; /* This will take up all the space on the left side */
`;

const DateGroup = styled.div`
  display: flex;
  gap: 15px; /* Reduced gap to make the date pickers closer */
  margin-right: 20px; /* Give space between the date fields and the close button */
  align-items: center;
`;

const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 150px; /* Adjust the width if needed */
  label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: ${colors.dashBoard.elementColor};
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  margin-bottom: 55px;
  color: ${colors.white};
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
`;

const TableHeader = styled.th`
  padding: 10px;
  text-align: left;
  background-color: ${colors.dashBoard.inputBg};
  color: ${colors.white};
`;

const TableCell = styled.td`
  padding: 10px;
  border-top: 1px solid ${colors.dashBoard.headerBg};
  text-align: left;
`;
