import React, { useEffect } from 'react'
import ReactQuill from "react-quill"
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { colors } from '../../Assets/styles/colors';

const RichTextComponents = ({ content, setContent, textAreaRef, promtKeywords, textAreaContainerRef }) => {


    const modules = {
        toolbar: false
    };

    const formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'color', 'background', 'align', 'custom'
    ];



    const handleChange = (content, delta, source, editor) => {
        setContent(content);
    };


    const changeKeywordColor = (quill, keywords) => {
        const text = quill.getText();
        keywords.forEach(element => {

            let index = text.indexOf(element);
            while (index !== -1) {
                quill.formatText(index, element.length, {
                    'color': colors.purple
                });

                quill.formatText(index + element.length, 1, 'color', false);
                index = text.indexOf(element, index + element.length);

            }
        });
    };

    function findFirstIndexBefore(str, index) {
        // Use lastIndexOf to find the first index of '[' before the given index
        return str.lastIndexOf('[', index - 1);
    }

    const onBackspacePress = (e) => {
        const quill = textAreaRef.current.getEditor();
        const range = quill.getSelection();

        if (e.key == "Backspace") {
            const prevIndex = findFirstIndexBefore(quill.getText(), range.index)
            if (!prevIndex) {
                return
            }
            const lenght = range.index - prevIndex
            const stringToDelete = quill.getText().substring(prevIndex, range.index)


            if (promtKeywords.includes(stringToDelete)) {
                quill.deleteText(prevIndex, lenght)
            }

        }
    }

    useEffect(() => {
        const quill = textAreaRef.current.getEditor();
        changeKeywordColor(quill, promtKeywords);
    }, [content])

    return (
        <Container ref={textAreaContainerRef}>
            <ReactQuill
                ref={textAreaRef}
                id='promptArea'
                theme='snow'
                modules={modules}
                formats={formats}
                value={content}
                onChange={handleChange}
                onKeyDown={(e) => onBackspacePress(e)}
            />
        </Container>
    )
}

export default RichTextComponents

const Container = styled.div`
display:flex;
height:100%;
width:100%;
background:${colors.dashBoard.headerBg};
border-radius:10px;
border:none;
resize:none;
outline:none;
padding:0px;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.white};
overflow:hidden;
.ql-editor {
    border:none;
  padding:30px;
    p{
     font-size: 16px;
     font-weight: 500;
     line-height: 22px;
     text-align: left;
    }

}

.ql-editor .ql-custom {
  color: red; /* Example: Change color to red */
  font-weight: bold; /* Example: Apply bold font */
}


.ql-container {
    border:none;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    height:100%;
}
`