import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as BackArrow } from "../../Assets/icons/BackArrowLong.svg"
import PrimaryButton from '../Generic Components/PrimaryButton'
import { Popover } from 'react-tiny-popover'
import { useLlmService } from '../../Context/LLMContext'
import RichTextComponents from './RichTextComponents'


const SelectMenu = ({ list, onClick }) => {
    return (

        <MenuWrapper>
            {list.map((item, index) => (
                <MenuElement key={index} onClick={(e) => onClick(item)}>
                    <p>{item}</p>
                </MenuElement>
            ))}

        </MenuWrapper>
    )
}
const DefinePromptModal = ({ handleCloseModal, selectedModel, handleSavePrompt, handleSaveParams }) => {

    const initialPromptText = selectedModel.prompt.text
    const formattedText = initialPromptText.replace(/\n/g, '</br>');
    const [textValue, setTextValue] = useState(formattedText);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false)
    const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
    const [cursorPosition, setCursorPosition] = useState(null);

    const { promtKeywords } = useLlmService();
    const textAreaRef = useRef();
    const textAreaContainerRef = useRef();

    const [temperature, setTemperature] = useState(selectedModel.temperature)
    const [topP, setTopP] = useState(selectedModel.top_p)
    const [topK, setTopK] = useState(selectedModel.top_k)
    const [responseLenght, setResponseLenght] = useState(selectedModel.response_length)
    const [paramsChanged, setParamsChanged] = useState(false)
    const [promptChanged, setPromptChanged] = useState(false)

    const handleFilterFloatNumbers = (setFunction, input) => {
        let newInput = input.replace(/[^0-9.]/g, '')
        setFunction(newInput);
    }

    const handleFilterNumbers = (setFunction, input) => {
        let newInput = input.replace(/[^0-9]/g, '')
        setFunction(newInput);
    }


    const handleOnKeyPress = (e) => {
        if (e.key === "[") {
            const textarea = textAreaRef.current.getEditor();
            const range = textarea.getSelection();

            if (range) {
                setCursorPosition(range.index)
                const bounds = textarea.getBounds(range.index);
                const editorContainer = textAreaContainerRef.current;
                const containerRect = editorContainer.getBoundingClientRect();
                setCoordinates({
                    y: 130 + containerRect.top + bounds.top,
                    x: 95 + bounds.left
                });
                setIsPopoverOpen(true)
            }

        }
        else {
            setIsPopoverOpen(false)
        }
    }

    const verifyPromptChanged = () => {
        const equalValues = initialPromptText === textAreaRef?.current?.getEditor().getText()
        setPromptChanged(!equalValues)
    }

    const verifyParamsChanged = () => {
        const equalValues = temperature == selectedModel.temperature &&
            topP == selectedModel.top_p &&
            topK == selectedModel.top_k &&
            responseLenght == selectedModel.response_length
        setParamsChanged(!equalValues)
    }


    const handleKeywordClick = (item) => {
        insertWordAtCursor(item)
        setIsPopoverOpen(false)
    }

    const insertWordAtCursor = (item) => {
        const quill = textAreaRef.current.getEditor();
        if (cursorPosition) {
            quill.deleteText(cursorPosition, 1);
            quill.insertText(cursorPosition, item + " ");
            quill.setSelection(cursorPosition + item.length + 1); // Move cursor to the end of the inserted word
        }
    };



    const handleSave = () => {
        if (promptChanged) {
            handleSavePrompt(selectedModel.id, textAreaRef.current.getEditor().getText())
        }
        if (paramsChanged) {
            handleSaveParams(selectedModel.id, temperature, topK, topP, responseLenght)
        }
    }

    useEffect(() => {
        let element = document.getElementById('promptArea');
        element.addEventListener("keydown", handleOnKeyPress)
        return () => element.removeEventListener('keydown', handleOnKeyPress)

    }, [])

    useEffect(() => {
        setTemperature(selectedModel.temperature)
        setTopP(selectedModel.top_p)
        setTopK(selectedModel.top_k)
        setResponseLenght(selectedModel.response_length)
    }, [selectedModel])


    useEffect(() => {
        verifyPromptChanged();
        verifyParamsChanged();
    }, [textValue, temperature, topK, topP, responseLenght, selectedModel])
    return (
        <Container>
            <Line>
                <BackBtn onClick={() => handleCloseModal()}>
                    <BackArrow />
                    Back
                </BackBtn>
                <Title>Define Prompt for {selectedModel.name}</Title>
                <PrimaryButton

                    disabled={!promptChanged && !paramsChanged}
                    text={"Save"}
                    style={{ maxWidth: "130px", marginLeft: "auto" }}
                    onClick={(e) => handleSave()}

                />
            </Line>

            <Line style={{ height: "100%", overflow: "hidden" }}>
                <RichTextComponents
                    textAreaRef={textAreaRef}
                    content={textValue}
                    setContent={setTextValue}
                    promtKeywords={promtKeywords}
                    textAreaContainerRef={textAreaContainerRef}
                />
                <OtherParamsWrapper>
                    <Group>
                        <GroupTitle>Temperature</GroupTitle>
                        <CustomInput
                            value={temperature}
                            onChange={(e) => handleFilterFloatNumbers(setTemperature, e.currentTarget.value)}
                            placeholder='-'
                        />
                    </Group>
                    <Group>
                        <GroupTitle>Top K</GroupTitle>
                        <CustomInput
                            value={topK}
                            onChange={(e) => handleFilterNumbers(setTopK, e.currentTarget.value)}
                            placeholder='-'
                        />
                    </Group>
                    <Group>
                        <GroupTitle>Top P</GroupTitle>
                        <CustomInput
                            value={topP}
                            onChange={(e) => handleFilterFloatNumbers(setTopP, e.currentTarget.value)}
                            placeholder='-'
                        />
                    </Group>

                    <Group>
                        <GroupTitle>Response Length</GroupTitle>
                        <CustomInput
                            value={responseLenght}
                            onChange={(e) => handleFilterNumbers(setResponseLenght, e.currentTarget.value)}
                            placeholder='-'
                        />
                    </Group>

                </OtherParamsWrapper>
            </Line>

            <Popover
                onClickOutside={() => setIsPopoverOpen(false)}
                isOpen={isPopoverOpen}
                positions={['right', 'bottom']} // preferred positions by priority
                content={
                    <SelectMenu
                        list={promtKeywords}
                        onClick={handleKeywordClick}
                    />}
            >
                <DropdownWrapper top={coordinates.y} left={coordinates.x}>
                </DropdownWrapper>
            </Popover>

        </Container >
    )
}

export default DefinePromptModal

const Container = styled.div`
height:100%;
display:flex;
flex-direction:column;
gap:30px;
position:absolute;
background:${colors.dashBoard.bg};
width:100%;
top:0;
bottom:0;
left:0;
padding:54px;
overflow:hidden;
`
const Line = styled.div`
display:flex;
align-items:center;
gap:30px;
width:100%;
position:relative;

`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:10px;

`
const GroupTitle = styled.p`
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.white};
`
const Title = styled.div`
font-size: 25px;
font-weight: 500;
line-height: 25px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
`
const BackBtn = styled.button`
display:flex;
gap:5px;
align-items:center;
justify-content:center;
width:fit-content;
background:none;
color:${colors.white};
font-size: 16px;
font-weight: 500;
line-height: 18px;
text-align: left;
border:none;
cursor:pointer;
`
const PromptArea = styled.textarea`

height:100%;
width:100%;
background:${colors.dashBoard.headerBg};
border-radius:10px;
border:none;
resize:none;
outline:none;
padding:30px;

font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;

color:${colors.white};
`
const DropdownWrapper = styled.div`
position:absolute;
color:red;
top:${props => props.top}px;
left:${props => props.left}px;
`

const MenuWrapper = styled.div`
display:flex;
flex-direction:column;
background:${colors.chatsListBg};
border-radius:10px;
padding:7px 4px;
`
const MenuElement = styled.div`
display:flex;
border-radius:10px;
cursor:pointer;
&:hover{
    background:${colors.purple20};
}
p{
color:${colors.purple};
    font-size: 16px;
   font-weight: 500;
   line-height: 22px;
   }
`


const OtherParamsWrapper = styled.div`
display:flex;
flex-direction:column;
gap:30px;

height:100%;
`

const CustomInput = styled.input`
width:100%;
padding:8px 16px;
border-radius:10px;
background:${colors.dashBoard.inputBg};
border:none;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.dashBoard.elementColor};
&:focus{
    outline:none;
}
`