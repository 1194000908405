import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { colors } from '../../Assets/styles/colors'
import { ReactComponent as CloseIcon } from '../../Assets/icons/closeIcon.svg'
import { ReactComponent as EyeIcon } from '../../Assets/icons/eyeIcon.svg'
import PrimaryButton from '../Generic Components/PrimaryButton'
import { useAuthService } from '../../Context/AuthContext'
import { notifyError, notifySuccess } from '../../Helpers/Notifications'
import { useOutletContext } from 'react-router-dom'

const ChangePasswordModal = ({ handleCloseModal, FAEnabled, setIsLoading }) => {

    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [passwordConfirm, setPasswordConfirm] = useState("")
    const [optCode, setOtpCode] = useState("");
    const { changeUserPassword } = useAuthService()

    const handleChangeVisibility = (id) => {
        const element = document.getElementById(id)
        if (element.type === "password") {
            element.type = "text"
        } else {
            element.type = "password"
        }

    }

    const handleChangePassword = () => {
        setIsLoading(true)
        const data = {
            old_password: oldPassword,
            new_password: newPassword
        }
        if (FAEnabled) {
            data.otp_code = optCode
        }

        changeUserPassword(
            data,
            (response) => {
                setIsLoading(false);
                notifySuccess("Password changed")
                handleCloseModal()
            },
            (error) => {
                notifyError("Something wrong")
                setIsLoading(false)
            }
        )
    }

    const checkDisabled = () => {
        const passwordsValid = oldPassword.length > 7 && newPassword.length > 7 && passwordConfirm.length > 7 && newPassword === passwordConfirm
        const otpValid = optCode.length > 3


        if (FAEnabled) {
            if (passwordsValid && otpValid) {
                return false
            } else {
                return true
            }

        } else {
            if (passwordsValid) {
                return false
            } else {
                return true
            }

        }

    }

    useEffect(() => {
        const close = (e) => {
            if (e.keyCode === 27) {
                handleCloseModal()
            }
        }
        window.addEventListener('keydown', close)
        return () => window.removeEventListener('keydown', close)
    }, [])


    return (
        <FullScreenContainer>
            <Card>
                <CloseBtn >
                    <CloseIcon onClick={() => handleCloseModal()} />
                </CloseBtn>

                <Title>
                    <h1>Change Password</h1>
                </Title>

                <Group>
                    <Title>
                        <h2>Old password</h2>
                    </Title>
                    <Line>
                        <CustomInput
                            type="password"
                            name='oldPassword'
                            id='oldPassword-id'
                            value={oldPassword}
                            onChange={(e) => setOldPassword(e.currentTarget.value)}
                        />

                        <IconWrapper onClick={() => handleChangeVisibility("oldPassword-id")}>
                            <EyeIcon />
                        </IconWrapper>

                    </Line>

                    <Title>
                        <h2>New password</h2>
                    </Title>
                    <Line>
                        <CustomInput
                            type="password"
                            name='newPassword'
                            id='password-id'
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.currentTarget.value)}
                        />
                        <IconWrapper onClick={() => handleChangeVisibility("password-id")}>
                            <EyeIcon />
                        </IconWrapper>
                    </Line>

                    <Title>
                        <h2>New password confirmation</h2>
                    </Title>

                    <Line>
                        <CustomInput
                            type="password"
                            name='confirmPassword'
                            id='passwordConfirm-id'
                            value={passwordConfirm}
                            onChange={(e) => setPasswordConfirm(e.currentTarget.value)}
                        />
                        <IconWrapper onClick={() => handleChangeVisibility("passwordConfirm-id")}>
                            <EyeIcon />
                        </IconWrapper>
                    </Line>

                    {FAEnabled &&
                        <>
                            <Title>
                                <h2>OTP Code</h2>
                            </Title>
                            <CustomInput
                                value={optCode}
                                onChange={(e) => setOtpCode(e.currentTarget.value)}
                            />
                        </>
                    }

                </Group>

                <PrimaryButton
                    disabled={checkDisabled()}
                    onClick={() => handleChangePassword()}
                    text={"Change password"}
                />

            </Card>
        </FullScreenContainer>
    )
}

export default ChangePasswordModal

const FullScreenContainer = styled.div`
position:fixed;
top:0;
left:0;
height:100vh;
width:100%;
background:rgba(0,0,0,0.3);
z-index:5;
display:flex;
justify-content:center;
align-items:center;
`
const Card = styled.div`
display:flex;
flex-direction:column;
padding:55px;
border-radius:12px;
gap:50px;
background:${colors.newChatModalBg};
position:relative;
min-width:600px;
min-height:500px;
`

const CloseBtn = styled.button`
display:flex;
align-items:center;
justify-content:center;
border:none;
background:none;
cursor:pointer;
position:absolute;
top:20px;
right:20px;
z-index:2;
font-size:20px;
font-weight:600;
color:${colors.white};

`
const Group = styled.div`
display:flex;
flex-direction:column;
gap:20px;
`

const CustomInput = styled.input`
width:100%;
padding:8px 16px;
border-radius:10px;
background:${colors.dashBoard.inputBg};
border:none;
font-size: 16px;
font-weight: 500;
line-height: 22px;
text-align: left;
color:${colors.dashBoard.elementColor};
&:focus{
    outline:none;
}
`
const Title = styled.div`
h1{
font-size: 20px;
font-weight: 600;
line-height: 30px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
h2{
    font-size: 16px;
font-weight: 600;
line-height: 24px;
letter-spacing: 0em;
text-align: left;
color:${colors.dashBoard.elementColor};
}
`
const Line = styled.div`
display:flex;
gap:20px;
align-items:center;

`
const IconWrapper = styled.div`
display:flex;
justify-content:center;
align-items:center;

svg{
height:20px;
width:20px;
color:${colors.dashBoard.elementColor};
cursor:pointer;
}

`