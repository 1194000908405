
import axios from "axios";
import LocalStorage from "../Helpers/LocalStorage";
import { refreshAdminToken } from "./RefreshTokenHelper";


const URL = process.env.REACT_APP_API_URL;

const buildConfig = (method, path, data = undefined, controller = null) => {
    var config = {
        cancelToken: controller?.token,
        method: method,
        url: `${URL}${path}`,
        headers: {
            Authorization: `Bearer ${LocalStorage.getAuthenticationToken()}`,
        },
        data: data,
    };
    return config;
};

const makeRequest = (config, onSuccess, onError, retry) => {
    axios(config)
        .then(function (response) {
            onSuccess(response);
        })
        .catch(function (error) {
            if (error.code == "ERR_CANCELED") {
                return;
            }
            console.log("ERR", error);
            if (error.response.status === 401 && retry === true) {
                refreshAdminToken(
                    LocalStorage.getRefreshToken(),
                    function () {
                        let newConfig = config;
                        newConfig.headers.Authorization = `Bearer ${LocalStorage.getAuthenticationToken()}`
                        makeRequest(newConfig, onSuccess, onError, (retry = false));
                    },
                    function (error) {
                        onError(error);
                    }
                )
            } else {
                onError(error);
            }
        });
};
export const GET = (
    path,
    onSuccess,
    onError,
    retry = true,
    controller = null
) => {
    controller?.cancel();
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const config = buildConfig("GET", path, undefined, source);
    makeRequest(config, onSuccess, onError, retry);

    return source;
};
export const POST = (
    path,
    data,
    onSucces,
    onError,
    retry = true,
    controller = null,
) => {
    controller?.cancel();

    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();
    const config = buildConfig("POST", path, data, source);
    makeRequest(config, onSucces, onError, retry);

    return source;
};
export const PATCH = (path, data, onSucces, onError, retry = true) => {
    const config = buildConfig("PATCH", path, data);
    makeRequest(config, onSucces, onError, retry);
};
export const PUT = (path, data, onSucces, onError, retry = true) => {
    const config = buildConfig("PUT", path, data);
    makeRequest(config, onSucces, onError, retry);
};
export const DELETE = (path, onSuccess, onError, retry = true) => {
    const config = buildConfig("DELETE", path);
    makeRequest(config, onSuccess, onError, retry);
};
