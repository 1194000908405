import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Assets/styles/colors";
import PrimaryButton from "../../Generic Components/PrimaryButton";
import { useLicensingContext } from "../../../Context/LicensingContext";
import { notifyError, notifySuccess } from "../../../Helpers/Notifications";

const SendOfferModal = ({
  isOpen,
  onClose,
  projects,
  offerID,
  setIsLoading,
}) => {
  const [isGenerated, setIsGenerated] = useState(false);
  const [generatedLink, setGeneratedLink] = useState("");
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const { generateOfferingLink } = useLicensingContext();

  const handleGenerate = () => {
    if (!selectedProjectId || !offerID) return; // If no project is selected, don't generate a link
    setIsLoading(true);
    generateOfferingLink(
      selectedProjectId,
      offerID,
      (response) => {
        const url = response.data?.url;
        if (url) {
          setGeneratedLink(url);
          setIsGenerated(true);
        }
        setIsLoading(false);
      },
      (error) => {
        notifyError(
          "Can not generate link, the user might be subscribed to another subscription!"
        );
        setIsLoading(false);
      }
    );
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(generatedLink);
    notifySuccess("Link copied to clipboard!");
  };

  // Reset the isGenerated state to false when the modal is shown (isOpen is true)
  useEffect(() => {
    if (isOpen) {
      setIsGenerated(false);
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <ModalOverlay>
      <ModalContainer>
        <ModalHeader>
          <h2>Generate Subscription link for project</h2>
          <CloseButton onClick={onClose}>×</CloseButton>
        </ModalHeader>
        <ModalContent>
          {!isGenerated ? (
            <>
              <SelectWrapper>
                <Label>Select Project</Label>
                <Select
                  value={selectedProjectId || ""}
                  onChange={(e) => setSelectedProjectId(Number(e.target.value))}
                >
                  <option value="" disabled>
                    -- Select a Project --
                  </option>
                  {projects.map((project) => (
                    <option key={project.id} value={project.id}>
                      {project.name}
                    </option>
                  ))}
                </Select>
              </SelectWrapper>
              <PrimaryButton
                icon={null}
                disabled={!selectedProjectId}
                text="Generate link"
                onClick={handleGenerate}
              />
            </>
          ) : (
            <LinkSection>
              <Label>Generated Link:</Label>
              <LinkField value={generatedLink} readOnly />
              <PrimaryButton
                icon={null}
                text="Copy link"
                onClick={handleCopy}
              />
            </LinkSection>
          )}
        </ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default SendOfferModal;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: ${colors.background};
  width: 500px;
  max-width: 90%;
  max-height: 70vh;
  padding: 20px;
  border-radius: 8px;
  overflow-y: auto;
  color: ${colors.white};
`;

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  h2 {
    font-size: 20px;
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: ${colors.white};
`;

const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Label = styled.label`
  font-size: 14px;
  color: ${colors.white};
`;

const Select = styled.select`
  padding: 8px;
  border: 1px solid ${colors.dashBoard.tableBorder};
  border-radius: 4px;
  background-color: ${colors.dashBoard.inputBg};
  color: ${colors.white};
  cursor: pointer;
  padding-right: 30px; /* Add padding to make space for the arrow */
  appearance: none; /* Remove default dropdown styling */
  -webkit-appearance: none; /* For WebKit browsers */
  background-image: url('data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 10 10"><path fill="%23ECECEC" d="M0 0l5 5 5-5z"/></svg>'); /* Custom down arrow */
  background-repeat: no-repeat;
  background-position: center right 10px; /* Align horizontally to the right */
  background-position-y: calc(50% + 5px); /* Center vertically and shift down */
  background-size: 12px; /* Adjust arrow size */
`;

const GenerateButton = styled(PrimaryButton)`
  background: ${colors.green};
  width: auto;
  padding: 6px 12px;
  font-size: 14px;
  margin-top: 10px;
`;

const LinkSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

const LinkField = styled.input`
  padding: 8px;
  border: 1px solid ${colors.dashBoard.tableBorder};
  border-radius: 4px;
  background-color: ${colors.dashBoard.inputBg};
  color: ${colors.white};
  cursor: not-allowed;
`;

const CopyButton = styled.button`
  padding: 10px;
  background: ${colors.purple};
  color: ${colors.white};
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  font-size: 14px;

  &:hover {
    background: ${colors.primaryHover};
  }
`;
