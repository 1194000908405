import React from "react";
import { RotatingLines } from "react-loader-spinner";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";

const SpinnerComponent = ({ children, isLoading }) => {
  return (
    <Container>
      {isLoading && (
        <SpinnerWrapper>
          <RotatingLines
            strokeColor={colors.purple}
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </SpinnerWrapper>
      )}
      {children}
    </Container>
  );
};

export default SpinnerComponent;
const Container = styled.div`
  position: relative;
`;
const SpinnerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  background: rgba(0, 0, 0, 0.3);
`;
