import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { colors } from "../../../Assets/styles/colors";
import { notifySuccess, notifyError } from "../../../Helpers/Notifications";
import { Link } from "react-router-dom"; // Assuming you're using react-router for navigation
import { useLicensingContext } from "../../../Context/LicensingContext";
import ProjectDetailsModal from "./ProjectDetailsModal"; // Import the modal

const PlatformAnalytics = ({ setIsLoading }) => {
  const [projects, setProjects] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);

  const { getAPIProjects } = useLicensingContext();

  const handleGetProjects = () => {
    setIsLoading(true);
    getAPIProjects(
      (response) => {
        setProjects(response.data.projects);
        setIsLoading(false);
      },
      (error) => {
        notifyError("Something went wrong");
        setIsLoading(false);
      }
    );
  };

  const handleOpenModal = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null);
  };

  useEffect(() => {
    handleGetProjects();
  }, []);

  const sortedProjects = projects.sort(
    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
  );

  return (
    <PlatformAnalyticsContainer>
      <h2>Projects</h2>
      <ProjectsList>
        {sortedProjects.map((project) => (
          <ProjectItem key={project.id}>
            <ProjectName>{project.name}</ProjectName>
            <RightSideContainer>
              <CreatedAt>
                {new Date(project.created_at).toLocaleDateString()}
              </CreatedAt>
              <DetailsLink onClick={() => handleOpenModal(project)}>
                See Details
              </DetailsLink>
            </RightSideContainer>
          </ProjectItem>
        ))}
      </ProjectsList>

      {/* Pass project details to the modal */}
      {selectedProject && (
        <ProjectDetailsModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          projectID={selectedProject.id}
          setIsLoading={setIsLoading}
        />
      )}
    </PlatformAnalyticsContainer>
  );
};

export default PlatformAnalytics;

const PlatformAnalyticsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 0px;
  background: ${colors.dashBoard.bg};
  height: 100vh;
  position: relative;
  color: ${colors.white};
  overflow-y: auto;
`;

const ProjectsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding-bottom: 200px;
`;

const ProjectItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 5px;
  background: ${colors.dashBoard.inputBg};
`;

const ProjectName = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-left: 20px;
`;

const RightSideContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const CreatedAt = styled.div`
  font-size: 14px;
  color: ${colors.white50};
`;

const DetailsLink = styled(Link)`
  font-size: 14px;
  color: ${colors.purple};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
