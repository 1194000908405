import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../Assets/styles/colors";
import CustomDatePicker from "./CustomDatePicker";
import { auditLogsAPI } from "../../API/logs/auditLogs";
import fileDownload from "js-file-download";
import moment from "moment";

const LogsOptionPage = ({ setIsLoading }) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleAudit = () => {
    let start_date = moment(startDate).startOf("day");
    let end_date = moment(endDate).endOf("day");
    setIsLoading(true);
    auditLogsAPI(
      start_date.toISOString(),
      end_date.toISOString(),
      function (response) {
        fileDownload(
          response.data,
          `Logs ${moment().format("DD-MM-yyyy , hh-mm")}.csv`
        );
        setIsLoading(false);
      },
      function (error) {
        setIsLoading(false);
        console.log(error);
      }
    );
  };

  return (
    <LogsOptionPageContainer>
      <Title>
        <h1>Audit Logs</h1>
      </Title>
      <DateGroup>
        <Group>
          <label>Start Date</label>
          <CustomDatePicker value={startDate} setValue={setStartDate} />
        </Group>
        <Group>
          <label>End Date</label>
          <CustomDatePicker value={endDate} setValue={setEndDate} />
        </Group>
      </DateGroup>
      <DownloadWrapper>
        <DownloadLogBtn onClick={() => handleAudit()}>
          Donwnload logs
        </DownloadLogBtn>
      </DownloadWrapper>
    </LogsOptionPageContainer>
  );
};

export default LogsOptionPage;

const LogsOptionPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  padding: 35px 50px;
  background: ${colors.dashBoard.bg};
  height: 100vh;
  position: relative;
`;
const Group = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-width: 250px;
  label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    text-align: left;
    color: ${colors.dashBoard.elementColor};
  }
`;
const DateGroup = styled.div`
  display: flex;
  gap: 40px;
`;
const Title = styled.div`
  h1 {
    font-size: 25px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: ${colors.dashBoard.elementColor};
  }
`;
const DownloadWrapper = styled.div``;
const DownloadLogBtn = styled.button`
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: none;
  background: ${colors.purple};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  color: ${colors.dashBoard.elementColor};
  border-radius: 10px;
  width: 540px;
`;
