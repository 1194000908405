import { POST } from "../API";



export const signInAdmin = (email, password, otpCode, onSuccess, onError) => {

    const data = {
        grant_type: "password",
        email: email,
        password: password,
        // client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET,
    };

    if (otpCode != "") {
        data.otp_attempt = otpCode
    }

    POST("/api/v1/identity/admins/sign_in", data, onSuccess, onError);
};

