import { GET, POST } from "../API"



export const getCurrentUserAPI = (
    onSuccess,
    onError
) => {

    GET(`/api/v1/identity/admins/current_user_details`, onSuccess, onError, true)
}


export const changePasswordAPICall = (
    data,
    onSuccess,
    onError
) => {

    POST(`/api/v1/identity/admins/change_password`, data, onSuccess, onError, true)
}


