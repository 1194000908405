import { POST } from "../../API";

export const addNewUsersMessageAPI = (data, onSuccess, onError) => {
  POST(
    `/api/v1/administration/emotii_chat/add_new_user_message`,
    data,
    onSuccess,
    onError
  );
};
